// @flow
import * as React from 'react'

export default function createButtonIndices(
  totalPages: number,
  currentPage: number,
  displayedIcons: number
): Array<number> {
  return React.useMemo(() => {
    let buttonsAtEachSide = Math.floor(displayedIcons / 2)
    let startIndex =
      currentPage <= buttonsAtEachSide + 1
        ? 0
        : totalPages - currentPage < buttonsAtEachSide + 1
        ? totalPages - buttonsAtEachSide * 2 - 1
        : currentPage - buttonsAtEachSide

    let finalDisplayedIcons = displayedIcons > totalPages ? totalPages : displayedIcons

    let result = Array<number>(finalDisplayedIcons)
    for (let i = 0; i < result.length; i++) {
      result[i] = startIndex + i
    }
    return result
  }, [totalPages, currentPage, displayedIcons])
}
