import * as React from 'react'
import styled from 'styled-components'
import useLazyImageSrc from 'hooks/useLazyImageSrc'
import Button from 'theme/molecules/Button'
import Link from 'theme/atoms/Link'
import { Product } from 'modules/products/types'
import Markdown from 'theme/atoms/Markdown'
import { ms } from 'modules/browser/const'
import ProductWidget from 'theme/molecules/ProductWidget'
import Pagination from 'theme/molecules/Pagination'
import { fetchHits } from './utils'

type Props = {
  dealId: string
  position: 'left' | 'right'
  context: {
    dealExists: boolean
    title: string
    description: string
    teaserImageUrl: string
    clickoutUrl: {
      to: string
      // cpc attributes
    }
    merchantName: string
    searchQueryStrings: string[]
    showPagination: boolean
    products: Product[]
    base64: string
    totalPages: number
  }
}

const hpp = 3

export default function Deal(props: Props) {
  const [products, page, setPage] = useProducts(
    props.context.searchQueryStrings,
    props.context.products
  )
  const [ref, image] = useLazyImageSrc(props.context.teaserImageUrl, props.context.base64)

  if (!props.context.dealExists) return null

  return (
    <Wrapper className="Deal">
      <div className="teaser">
        <div className="brand-wrapper">
          <div className="logo" ref={ref}>
            <img src={image} alt={props.context.merchantName} />
          </div>
          <Link className="btn-wrapper" {...props.context.clickoutUrl}>
            <Button filled>jetzt shoppen</Button>
          </Link>
        </div>
        <div className="description-wrapper">
          <Markdown centered>{props.context.description}</Markdown>
        </div>
        <div className="mobile-btn">
          <Link className="btn-wrapper" {...props.context.clickoutUrl}>
            <Button filled>jetzt shoppen</Button>
          </Link>
        </div>
      </div>
      {!!products.length && (
        <div className="products">
          {products.map((hit, i) => (
            <ProductWidget
              key={hit.objectID}
              product={hit}
              listName="Deal"
              index={i + page * hpp}
            />
          ))}
        </div>
      )}

      {props.context.showPagination && props.context.totalPages > 1 && (
        <div className="pagination">
          <Pagination onChange={setPage} page={page} totalPages={props.context.totalPages} />
        </div>
      )}
    </Wrapper>
  )
}

function useProducts(queryStrings, initialHits) {
  const [hits, setHits] = React.useState(initialHits)
  const [page, setPage] = React.useState(0)
  const isFirstRender = React.useRef(true)

  // do something
  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    fetchHits(hpp, queryStrings, page).then(([hits]) => {
      setHits(hits)
    })
  }, [page, hpp, queryStrings])

  return [hits, page, setPage]
}

const Wrapper = styled.div`
  padding: 15px;
  background: #eeeced;
  > .teaser {
    display: flex;
    flex-direction: column;
    @media (min-width: ${ms.LAPTOP}px) {
      flex-direction: row;
    }

    > .brand-wrapper {
      flex: 1;
      text-align: center;
      padding: 10px;

      > .btn-wrapper > button {
        display: none;
        padding: 10px;
        font-size: 18px;
        @media (min-width: ${ms.LAPTOP}px) {
          display: inline;
        }
      }
    }
    > .description-wrapper {
      flex: 1;
      display: flex;
      min-height: 150px;
      flex-direction: column;
      justify-content: center;
      background: rgb(85, 85, 85);
      color: white !important;
      > * {
        padding: 10px;
        strong {
          color: white !important;
        }
      }
    }

    > .mobile-btn {
      text-align: center;
      padding: 15px;

      > .btn-wrapper > button {
        padding: 10px;
        font-size: 18px;
        @media (min-width: ${ms.LAPTOP}px) {
          display: none;
        }
      }
    }
  }

  > .products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    margin-top: 15px;

    @media (min-width: ${ms.LAPTOP}px) {
      grid-template-columns: repeat(3, 1fr);
    }

    > .ProductWidget:nth-child(3) {
      display: none;
    }
    @media (min-width: ${ms.LAPTOP}px) {
      > .ProductWidget:nth-child(3) {
        display: block;
      }
    }
  }

  > .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
`
