// import fetchCreateAlgoliaHelper from 'utils/fetchCreateAlgoliaHelper'
// import config from 'config'

export async function fetchHits(hpp, queryStrings, page) {
  // const createAlgoliaHelper = await fetchCreateAlgoliaHelper()
  // // @ts-ignore
  // const states = queryStrings.map((q) => createAlgoliaHelper.url.getStateFromQueryString(q))
  // const helpers = states.map((state) => {
  //   const helper = createAlgoliaHelper(config.indexes.products, {
  //     attributesToHighlight: [],
  //     disjunctiveFactes: ['gender'],
  //   })
  //   // @ts-ignore
  //   helper.setState(state)
  //   // @ts-ignore
  //   helper.setIndex(config.indexes.products)
  //   // @ts-ignore
  //   helper.setState(helper.state.addDisjunctiveFacet('gender'))
  //   helper.addDisjunctiveFacetRefinement('gender', 'women')
  //   helper.addDisjunctiveFacetRefinement('gender', 'unisex')
  //   helper.setPage(page)
  //   return helper
  // })
  // const hitsPerPage = Math.ceil(hpp / states.length)

  // const results = await Promise.all(helpers.map((helper) => helper.searchOnce({ hitsPerPage })))
  // // @ts-ignore
  // const hits = [].concat(...results.map((r) => r.content.hits))
  // const totalPages = 4 // TODO

  // return [hits, totalPages]
  return [0, 0]
}
