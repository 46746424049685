import * as React from 'react'
import styled from 'styled-components'
import Link from 'theme/atoms/Link'
import useButtonIndices from './hooks/useButttonIndices'

export type Props = {
  page: number
  totalPages: number
  onChange?: (page: number) => void
  resolvePath?: (page: number) => string
}

export default function Pagination(props: Props) {
  const buttonIndices = useButtonIndices(props.totalPages, props.page, 5)
  const hasPrevPage = props.page !== 0
  const hasNextPage = props.page !== props.totalPages - 1
  if (!hasPrevPage && !hasNextPage) {
    return null
  }

  const createButtonProps = (page: number, hide?: boolean) => {
    let args
    if (props.resolvePath) {
      args = {
        as: Link,
        to: hide ? undefined : props.resolvePath(page),
      }
    }
    return args
  }

  return (
    <Wrapper data-cy-ctx="molecules/Pagination" className="Pagination">
      <div className="items">
        <Item
          {...createButtonProps(props.page - 1, !hasPrevPage)}
          hidden={!hasPrevPage}
          position="left"
          data-cy-handle="prev"
          data-cy-state={hasPrevPage && 'prev-btn-visible'}
          onClick={() => props.onChange && props.onChange(props.page - 1)}
          children={'<'}
        />
        {buttonIndices.map((num) => (
          <Item
            {...createButtonProps(num)}
            key={num}
            active={num === props.page}
            data-cy-handle="page"
            onClick={() => props.onChange && props.onChange(num)}
            children={num + 1}
          />
        ))}
        <Item
          {...createButtonProps(props.page + 1, !hasNextPage)}
          hidden={!hasNextPage}
          position="right"
          data-cy-handle="next"
          data-cy-state={hasNextPage && 'next-btn-visible'}
          onClick={() => props.onChange && props.onChange(props.page + 1)}
          children={'>'}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  > .items {
    padding: 0 10px;
    background: white;
    position: relative;
  }
`

const Item = styled.button.attrs((props) => ({
  style: {
    display: props.hidden ? 'none' : 'inline-block',
    cursor: props.hidden ? 'normal' : 'pointer',
    position: props.position ? 'absolute' : 'relative',
    right: props.position === 'right' ? -20 : undefined,
    left: props.position === 'left' ? -20 : undefined,
  },
}))`
  color: #262626;
  top: 0;
  border: none;
  height: 30px;
  width: 30px;
  background: white;
  font-family: Raleway;
  font-size: 16px;
  letter-spacing: 2.4px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  margin: 0;
  padding-bottom: 2px;
  padding-left: 7px;
  padding-right: 4px;
  padding-top: 1px;
  &:focus {
    outline: none;
  }

  ${(props) =>
    props.active &&
    `
    &:before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      border-radius: 50px;
      background: grey;
      opacity: 0.3;
    }
  `}
`
